import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playing: false,
  playList: [],
  playListItem: [],
};

export const ttsSlice = createSlice({
  name: 'ttsSlice',
  initialState,
  reducers: {
    setPlayState: (state, item) => {
      state.playing = item.payload;
    },
    setPlayList: (state, item) => {
      state.playList = item.payload;
    },
    setPlayListItem: (state, item) => {
      state.playListItem = item.playListItem;
    },
    playSelected: (state, item) => {
      state.playList = item.payload;
    },
  },
});

export const { setPlayState } = ttsSlice.actions;

export default ttsSlice.reducer;
