import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

export const podcastModalSlice = createSlice({
  name: 'podcastModal',
  initialState,
  reducers: {
    togglePodcast: (state) => {
      state.open = !state.open;
    },
  },
});

export const { togglePodcast } = podcastModalSlice.actions;

export default podcastModalSlice.reducer;
