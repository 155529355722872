import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="71"
      fill="none"
      viewBox="0 0 70 71"
    >
      <g clipPath="url(#clip0_2556_80951)">
        <path
          fill="#27CCBC"
          d="M63.719 51.103H19.4A5.402 5.402 0 0014 56.505v5.893a5.401 5.401 0 005.401 5.401H63.72c-3.765-5.562-3.765-11.127 0-16.696z"
        ></path>
        <path
          fill="#323C6B"
          d="M56.028 55.624H32.91a.473.473 0 010-.946h23.118a.483.483 0 01.473.473.473.473 0 01-.473.473zM56.028 59.928H32.91a.473.473 0 010-.946h23.118a.473.473 0 01.473.473.482.482 0 01-.473.473zM56.028 64.223H32.91a.473.473 0 110-.946h23.118a.473.473 0 01.473.473.482.482 0 01-.473.473z"
        ></path>
        <path
          fill="#FCD462"
          d="M56 34.406H11.683a5.402 5.402 0 00-5.402 5.402V45.7a5.401 5.401 0 005.402 5.401H56c-3.765-5.562-3.765-11.127 0-16.696z"
        ></path>
        <path
          fill="#F7BE56"
          d="M56 34.406H20.811v2.47h31.216c-3.206 4.729-2.138 9.459.587 14.188H56c-3.765-5.537-3.765-11.09 0-16.658zM17.027 34.406h-5.345a5.393 5.393 0 00-4.55 2.507 3.86 3.86 0 01.577 0h9.318v-2.507z"
        ></path>
        <path
          fill="#21B3A9"
          d="M24.595 51.103h-5.193a5.393 5.393 0 00-4.55 2.507c.192-.014.384-.014.577 0h9.166v-2.507zM63.719 51.103H28.577v2.507h31.17c-3.208 4.73-2.139 9.46.586 14.19h3.386c-3.765-5.563-3.765-11.128 0-16.697z"
        ></path>
        <path
          fill="#EEEFEE"
          d="M21.133 34.406h-3.557a23.45 23.45 0 000 16.696h3.556a24.462 24.462 0 010-16.696z"
        ></path>
        <path
          fill="#323C6B"
          d="M48.3 38.928H25.18a.473.473 0 01-.473-.473.482.482 0 01.473-.473H48.3a.473.473 0 010 .946zM48.3 43.204H25.18a.483.483 0 01-.473-.473.473.473 0 01.473-.473H48.3a.473.473 0 110 .946zM48.3 47.528H25.18a.473.473 0 010-.946H48.3a.473.473 0 010 .946z"
        ></path>
        <path
          fill="#E7AA6C"
          d="M30.336 9.396c1.325-4.578 3.614-5.921 3.614-5.921l3.472 1.296a7.672 7.672 0 00-5.336 4.55"
        ></path>
        <path
          fill="#323C6B"
          d="M30.346 9.87a.303.303 0 01-.133 0 .473.473 0 01-.33-.587c1.362-4.73 3.726-6.14 3.83-6.196a.474.474 0 01.407 0l3.463 1.24a.473.473 0 01.312.472.482.482 0 01-.37.426 7.198 7.198 0 00-5.013 4.294.473.473 0 01-.86-.369 8.457 8.457 0 014.294-4.417l-1.892-.728c-.53.416-2.185 1.891-3.216 5.524a.483.483 0 01-.492.34z"
        ></path>
        <path
          fill="#FE7058"
          d="M44.667 16.88c0 4.729-3.783 17.528-8.513 17.528A8.513 8.513 0 0131.14 32.8a8.514 8.514 0 01-4.966 1.589c-4.73 0-8.513-12.799-8.513-17.529a8.513 8.513 0 0113.527-6.98 8.513 8.513 0 0113.527 6.98l-.048.02z"
        ></path>
        <path
          fill="#EA6453"
          d="M44.668 16.878c0 4.73-3.784 17.528-8.514 17.528a8.513 8.513 0 01-5.013-1.608 8.512 8.512 0 01-4.967 1.59 3.848 3.848 0 01-2.667-1.344 8.333 8.333 0 004.815-1.59 8.428 8.428 0 004.966 1.6c4.73 0 8.56-12.8 8.56-17.539a8.57 8.57 0 00-3.547-6.943 8.57 8.57 0 016.367 8.306z"
        ></path>
        <path
          fill="#323C6B"
          d="M16.951 70.733a.376.376 0 01-.142 0 8.75 8.75 0 01-5.836-6.149.487.487 0 11.946-.236 7.804 7.804 0 005.203 5.458.478.478 0 01-.142.946l-.029-.02zM11.162 62.87a.473.473 0 01-.473-.473v-.71a.482.482 0 01.473-.472.473.473 0 01.473.473v.71a.473.473 0 01-.473.472zM47.505 17.352a.473.473 0 01-.473-.473 10.85 10.85 0 00-3.121-7.624.473.473 0 01.336-.808c.125 0 .245.05.336.136a11.815 11.815 0 013.386 8.287.47.47 0 01-.464.482zM47.345 20.068a.473.473 0 01-.426-.52v-.483a.482.482 0 01.51-.435.473.473 0 01.436.51l-.047.502a.463.463 0 01-.473.426zM56 51.577H11.682a5.884 5.884 0 01-5.874-5.874V39.81a5.884 5.884 0 015.874-5.875H56a.473.473 0 01.416.256.491.491 0 010 .482c-3.68 5.44-3.68 10.727 0 16.167a.501.501 0 01-.175.67.482.482 0 01-.241.067zM11.682 34.882a4.938 4.938 0 00-4.928 4.928v5.893a4.938 4.938 0 004.928 4.928H55.13a13.99 13.99 0 010-15.75H11.682z"
        ></path>
        <path
          fill="#EEEFEE"
          d="M28.86 51.103h-3.565a23.497 23.497 0 000 16.696h3.566a24.349 24.349 0 010-16.696z"
        ></path>
        <path
          fill="#323C6B"
          d="M63.719 68.273H19.4a5.883 5.883 0 01-5.874-5.875v-5.893a5.884 5.884 0 015.874-5.874H63.72a.473.473 0 01.416.255.444.444 0 010 .483c-3.68 5.439-3.68 10.727 0 16.166a.445.445 0 010 .482.473.473 0 01-.416.256zM19.4 51.577a4.937 4.937 0 00-4.928 4.928v5.893a4.938 4.938 0 004.928 4.929h43.447a14.028 14.028 0 010-15.75H19.401z"
        ></path>
        <path
          fill="#E1E6E9"
          d="M17.264 34.406h3.434v2.47h-3.434v-2.47zM24.774 51.103h3.548v2.47h-3.548v-2.47z"
        ></path>
        <path
          fill="#323C6B"
          d="M21.133 51.575h-3.557a.472.472 0 01-.445-.302 24.103 24.103 0 010-17.027.472.472 0 01.445-.312h3.557a.472.472 0 01.444.633 24.103 24.103 0 000 16.375.474.474 0 01-.057.435.482.482 0 01-.387.198zm-3.226-.946h2.564a24.916 24.916 0 010-15.75h-2.564a23.1 23.1 0 000 15.75zM28.86 68.273h-3.565a.483.483 0 01-.445-.303 24.103 24.103 0 010-17.027.474.474 0 01.445-.312h3.566a.463.463 0 01.378.199.472.472 0 01.066.435 23.99 23.99 0 000 16.374.471.471 0 01-.066.435.463.463 0 01-.378.199zm-3.234-.946h2.573a24.793 24.793 0 010-15.75h-2.564a23.052 23.052 0 00-.01 15.75zM36.107 34.88a8.949 8.949 0 01-4.966-1.486 8.92 8.92 0 01-4.967 1.486c-5.287 0-9.033-13.65-9.033-17.973a9.043 9.043 0 019.033-9.044 8.854 8.854 0 014.967 1.495 8.883 8.883 0 014.966-1.495 9.053 9.053 0 019.034 9.044c0 4.323-3.746 17.973-9.034 17.973zm-4.966-2.536c.098 0 .193.03.274.086a8.013 8.013 0 004.73 1.504c4.237 0 8.087-12.298 8.087-17.027a8.107 8.107 0 00-8.087-8.098 8.012 8.012 0 00-4.73 1.504.483.483 0 01-.549 0 7.993 7.993 0 00-4.73-1.504 8.098 8.098 0 00-8.087 8.098c0 4.73 3.84 17.027 8.088 17.027a8.05 8.05 0 004.73-1.495.435.435 0 01.274-.095z"
        ></path>
        <path
          fill="#fff"
          d="M29.816 49.439h-.369a.587.587 0 01-.586-.587.576.576 0 01.586-.586h.37a.586.586 0 110 1.173zM38.614 49.448h-6.565a.586.586 0 01-.577-.596.566.566 0 01.586-.577h6.565a.55.55 0 01.587.548.624.624 0 01-.596.625z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2556_80951">
          <path
            fill="#fff"
            d="M0 0H70V70H0z"
            transform="translate(0 .637)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
