import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="71"
      fill="none"
      viewBox="0 0 70 71"
    >
      <path
        fill="#8237FD"
        fillOpacity="0.25"
        stroke="#000"
        d="M3.889 24.475a3.39 3.39 0 013.389 3.388V43.42a3.39 3.39 0 01-6.778 0V27.864a3.389 3.389 0 013.389-3.39zm62.222 0a3.39 3.39 0 013.364 2.978l.025.425v15.54a3.389 3.389 0 01-6.752.411l-.026-.425v-15.54a3.389 3.389 0 013.389-3.39z"
      ></path>
      <path
        fill="#8237FD"
        fillOpacity="0.5"
        stroke="#000"
        d="M50.555 12.807a3.389 3.389 0 013.39 3.388v38.89a3.389 3.389 0 01-6.779 0v-38.89a3.389 3.389 0 013.39-3.388zM19.445 12.807a3.39 3.39 0 013.388 3.388v38.89a3.389 3.389 0 11-6.777 0v-38.89a3.389 3.389 0 013.389-3.388z"
      ></path>
      <path
        fill="#8237FD"
        fillOpacity="0.75"
        d="M37.583 1.623a3.889 3.889 0 00-6.472 2.907v62.222l.027.455a3.889 3.889 0 007.75-.455V4.53l-.027-.455a3.889 3.889 0 00-1.278-2.452z"
      ></path>
    </svg>
  );
}

export default Icon;
