import { motion } from 'framer-motion';

import './index.scss';

const Button = ({
  onClick,
  className,
  children,
  background = 'var(--purpleTwo)',
  color = 'var(--white)',
  width = '100%',
  padding = '20px',
  borderRadius = '20px',
  fontWeight,
  type,
  disabled,
}) => {
  return (
    <motion.button
      className={`${className} button`}
      disabled={disabled}
      onClick={onClick}
      style={{ background, color, width, padding, borderRadius, fontWeight }}
      type={type}
    >
      {children}
    </motion.button>
  );
};

export default Button;
