import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const questionsProgressSlice = createSlice({
  name: 'digiSelectSlice',
  initialState,
  reducers: {
    questionProgress: (state, item) => {
      state.value = item.payload;
    },
  },
});

export const { questionProgress } = questionsProgressSlice.actions;

export default questionsProgressSlice.reducer;
