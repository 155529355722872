import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'first',
};

export const mobileLoginSlice = createSlice({
  name: 'mobileLoginSlice',
  initialState,
  reducers: {
    setScreen: (state, item) => {
      state.value = item.payload;
    },
  },
});

export const { setScreen } = mobileLoginSlice.actions;

export default mobileLoginSlice.reducer;
