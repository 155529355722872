import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  openName: '',
};

export const dropdownSlice = createSlice({
  name: 'dropdownSlice',
  initialState,
  reducers: {
    openDropdown: (state, item) => {
      state.open = true;
      state.openName = item.payload;
    },
    closeDropdown: (state) => {
      state.open = false;
      state.openName = '';
    },
  },
});

export const { openDropdown, closeDropdown } = dropdownSlice.actions;

export default dropdownSlice.reducer;
