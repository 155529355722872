import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const likeSlice = createSlice({
  name: 'like',
  initialState,
  reducers: {
    addLike: (state, item) => {
      const removeDuplicate = state.value.filter(
        (like) => like.id !== item.payload.id,
      );
      state.value = [...removeDuplicate, item.payload];
    },
  },
});

export const { addLike } = likeSlice.actions;

export default likeSlice.reducer;
