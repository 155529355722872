import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
};

export const fullScreenSlice = createSlice({
  name: 'fullScreen',
  initialState,
  reducers: {
    toggleFullScreen: (state) => {
      state.value = !state.value;
    },
  },
});

export const { toggleFullScreen } = fullScreenSlice.actions;

export default fullScreenSlice.reducer;
