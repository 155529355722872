import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="68"
      fill="none"
      viewBox="0 0 61 68"
    >
      <path
        fill="#18387A"
        d="M18.3 18.416c0-3.256 1.285-6.379 3.573-8.681A12.16 12.16 0 0130.5 6.139a12.16 12.16 0 018.627 3.596 12.317 12.317 0 013.573 8.681c0 3.257-1.285 6.38-3.573 8.682a12.161 12.161 0 01-8.627 3.596 12.161 12.161 0 01-8.627-3.596 12.318 12.318 0 01-3.573-8.681zm12.2-9.208a9.12 9.12 0 00-6.47 2.697 9.238 9.238 0 00-2.68 6.511c0 2.443.964 4.785 2.68 6.512a9.12 9.12 0 006.47 2.697 9.12 9.12 0 006.47-2.697 9.238 9.238 0 002.68-6.512 9.238 9.238 0 00-2.68-6.51 9.12 9.12 0 00-6.47-2.698zM9.15 39.903a6.124 6.124 0 016.127-6.14h19.905a12.227 12.227 0 00-1.248 3.07H15.277a3.065 3.065 0 00-3.077 3.07c0 4.017 1.897 7.01 5.103 9.064 3.263 2.096 7.905 3.213 13.197 3.213 2.775 0 5.377-.307 7.689-.902l.6 3.014c-2.573.645-5.38.958-8.29.958-5.657 0-10.927-1.185-14.837-3.693C11.69 49.01 9.15 45.09 9.15 39.903z"
      ></path>
      <path
        fill="#F9D51B"
        fillRule="evenodd"
        d="M30.031 42.97a12.807 12.807 0 001.696 6.377 12.705 12.705 0 004.639 4.667v.112c0 1.69.667 3.312 1.855 4.508a6.314 6.314 0 004.48 1.867c1.68 0 3.29-.672 4.479-1.867a6.395 6.395 0 001.855-4.508v-.111a12.71 12.71 0 004.637-4.667 12.813 12.813 0 000-12.75 12.71 12.71 0 00-4.636-4.666 12.607 12.607 0 00-12.668-.002 12.71 12.71 0 00-4.638 4.666 12.812 12.812 0 00-1.699 6.374z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#18387A"
        fillRule="evenodd"
        d="M30.031 42.97a12.807 12.807 0 001.696 6.377 12.705 12.705 0 004.639 4.667v.112c0 1.69.667 3.312 1.855 4.508a6.314 6.314 0 004.48 1.867c1.68 0 3.29-.672 4.479-1.867a6.395 6.395 0 001.855-4.508v-.111a12.71 12.71 0 004.637-4.667 12.813 12.813 0 000-12.75 12.71 12.71 0 00-4.636-4.666 12.607 12.607 0 00-12.668-.002 12.71 12.71 0 00-4.638 4.666 12.812 12.812 0 00-1.699 6.374zm19.004 7.127a9.574 9.574 0 002.9-4.872 9.62 9.62 0 00-.358-5.668 9.553 9.553 0 00-3.488-4.464 9.46 9.46 0 00-5.389-1.686 9.46 9.46 0 00-5.388 1.686 9.553 9.553 0 00-3.488 4.464 9.621 9.621 0 00-.357 5.668 9.574 9.574 0 002.899 4.872h3.167v4.029c0 .845.334 1.656.928 2.254a3.157 3.157 0 004.48 0 3.198 3.198 0 00.927-2.254v-4.032l3.167.001v.002z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#18387A"
        d="M39.534 62.105v-.01a6.277 6.277 0 003.167.853c1.154 0 2.236-.31 3.167-.852v.01c0 .845-.333 1.655-.927 2.253-.594.598-1.4.934-2.24.934-.84 0-1.646-.336-2.24-.934a3.198 3.198 0 01-.927-2.254z"
      ></path>
    </svg>
  );
}

export default Icon;
