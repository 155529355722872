import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {},
};

export const threeDSlice = createSlice({
  name: 'threeDSlice',
  initialState,
  reducers: {
    set3DContent: (state, item) => {
      state.value = item.payload;
    },
  },
});

export const { set3DContent } = threeDSlice.actions;

export default threeDSlice.reducer;
