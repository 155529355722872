import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const subjectSlice = createApi({
  reducerPath: 'subjectSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get_Subject'],
  endpoints: (builder) => ({
    getSubject: builder.query({
      query: () => `subject/match`,
      providesTags: ['Get_Subject'],
    }),
  }),
});

export const { useGetSubjectQuery } = subjectSlice;
