import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const digiSlice = createApi({
  reducerPath: 'digiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get_ALL'],
  endpoints: (builder) => ({
    // postComment: builder.mutation({
    //   query: (payload) => ({
    //     url: '/digiprenuers',
    //     method: 'POST',
    //     body: payload,
    //   }),
    //   // invalidatesTags: [{ type: 'Comment' }],
    // }),
    getDigi: builder.query({
      query: () => '/resource/digipreneurs',
      providesTags: ['GET_ALL'],
    }),
    getOneDigi: builder.query({
      query: (id) => `/resource/digipreneurs/${id}`,
      providesTags: ['GET_ONE'],
    }),
    getDigiLevel: builder.query({
      query: ({ groupId, levelId }) =>
        `/resource/digipreneurs/${groupId}/courselevels/${levelId}`,
      providesTags: ['GET_LEVEL'],
    }),
    getDigiDays: builder.query({
      query: ({ groupId, dayId }) =>
        `/resource/digipreneurs/${groupId}/coursedays/${dayId}`,
      providesTags: ['GET_LEVEL'],
    }),
  }),
});

export const {
  useGetDigiQuery,
  useGetOneDigiQuery,
  useGetDigiLevelQuery,
  useGetDigiDaysQuery,
} = digiSlice;
