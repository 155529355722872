import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const bookmarkSlice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    addBookmark: (state, item) => {
      const removeDuplicate = state.value.filter(
        (like) => like.id !== item.payload.id,
      );
      state.value = [...removeDuplicate, item.payload];
    },
  },
});

export const { addBookmark } = bookmarkSlice.actions;

export default bookmarkSlice.reducer;
