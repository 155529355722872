import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  playState: false,
  isLoading: false,
  fullScreen: false,
  progress: {},
  currentTime: 0,
};

export const videoPlayerSlice = createSlice({
  name: 'videoSlice',
  initialState,
  reducers: {
    enterFullScreen: (state, item) => {
      state.fullScreen = item.payload;
    },
    currentPlayTime: (state, item) => {
      state.currentTime = item.payload;
    },
    setPlaying: (state, item) => {
      state.playState = item.payload;
    },
    setIsLoading: (state, item) => {
      state.isLoading = item.payload;
    },
    resetState: (state) => {
      state.data = {};
      state.playState = false;
      state.isLoading = false;
      state.fullScreen = false;
      state.progress = {};
      state.currentTime = 0;
    },
  },
});

export const {
  enterFullScreen,
  currentPlayTime,
  setPlaying,
  setIsLoading,
  resetState,
} = videoPlayerSlice.actions;

export default videoPlayerSlice.reducer;
