import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const externalThreeDSlice = createApi({
  reducerPath: 'externalThreeDSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    // prepareHeaders: (headers, { getState }) => {
    //   const token = getState().token.value.token;
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  tagTypes: ['GET'],
  endpoints: (builder) => ({
    getThreeD: builder.query({
      query: ({ topicId, contentId, token }) =>
        `/resource/topic-content/${topicId}/content/${contentId}?token=${token}`,
      providesTags: ['GET'],
    }),
  }),
});

export const { useGetThreeDQuery } = externalThreeDSlice;
