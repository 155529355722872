import React from 'react';

const Icon = () => {
  return (
    <svg
      fill="none"
      height="62"
      viewBox="0 0 70 62"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M42.307.049a27.693 27.693 0 110 55.386h-1.63v3.225a3.29 3.29 0 01-3.293 3.29c-8.015-.006-16.134-2.68-22.295-8.158C8.873 48.26 4.846 40.04 4.84 29.4v-1.658A27.693 27.693 0 0132.534.049h9.774zm6.516 22.806a4.887 4.887 0 100 9.774 4.887 4.887 0 000-9.774z"
        fill="#F9A11B"
        fillOpacity="0.5"
        fillRule="evenodd"
      />
      <path
        d="M22.56 24.287a4.887 4.887 0 116.911 6.91 4.887 4.887 0 01-6.91-6.91z"
        fill="#fff"
      />
      <path
        d="M35.338 54.934h2.129a27.193 27.193 0 000-54.385h-9.774A27.193 27.193 0 00.5 27.742V29.4l34.838 25.535zm0 0v.5m0-.5v.5m0 0v3.226a2.79 2.79 0 01-2.794 2.79h0m2.794-6.016l-2.794 6.016m0 0c-7.908-.006-15.904-2.646-21.962-8.031m21.962 8.031v.5c-8.015-.006-16.134-2.68-22.294-8.158m0 0l.332-.373s0 0 0 0m-.332.373C4.033 48.26.007 40.04 0 29.4l10.25 24.392zm.332-.373C4.477 47.986.507 39.905.5 29.4l10.082 24.019z"
        fill="#89C0C4"
        stroke="#000"
      />
      <path
        d="M41.5 25.549c-.527 0-1.035.205-1.41.573-.377.37-.59.872-.59 1.397 0 .526.213 1.029.59 1.397.375.369.883.574 1.41.574h17.4c.527 0 1.035-.205 1.41-.574.377-.368.59-.87.59-1.397 0-.525-.213-1.028-.59-1.396a2.015 2.015 0 00-1.41-.574H41.5z"
        fill="#FBCF8C"
        stroke="#000"
      />
      <path
        d="M44.44 26.48c.28-.276.662-.431 1.06-.431h13.4c.398 0 .78.155 1.06.43.282.276.44.65.44 1.04s-.158.765-.44 1.04c-.28.276-.662.431-1.06.431H45.5c-.398 0-.78-.155-1.06-.43a1.456 1.456 0 01-.44-1.04c0-.39.158-.765.44-1.04z"
        fill="#fff"
      />
      <path
        d="M22.5 19.52c0-1.098-.905-1.971-2-1.971s-2 .873-2 1.97v6.853h-7c-.527 0-1.035.206-1.41.574-.377.369-.59.871-.59 1.397s.213 1.028.59 1.397c.375.368.883.574 1.41.574h7v6.264c0 .526.213 1.028.59 1.397.375.369.883.574 1.41.574.527 0 1.035-.205 1.41-.574.377-.369.59-.87.59-1.397v-6.265h6.4c.527 0 1.035-.205 1.41-.573.377-.369.59-.871.59-1.397s-.213-1.028-.59-1.397a2.015 2.015 0 00-1.41-.574h-6.4V19.52z"
        fill="#fff"
        stroke="#000"
      />
    </svg>
  );
};

export default Icon;
