import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'account',
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    navigationForward: (state, item) => {
      state.value = item.payload;
    },
    navigationBack: (state) => {
      if (state.value === 'phone') {
        state.value = 'account';
      }
      if (state.value === 'quick') {
        state.value = 'phone';
      }
      if (state.value === 'customise') {
        state.value = 'quick';
      }
    },
    navigationOtherScreen: (state) => {
      state.value = 'customise';
    },
  },
});

export const { navigationForward, navigationBack, navigationOtherScreen } =
  navigationSlice.actions;

export default navigationSlice.reducer;
