import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const studyGuidesSlice = createApi({
  reducerPath: 'studyGuidesSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['STUDY_GUIDES', 'GET_ONE'],
  endpoints: (builder) => ({
    getStudyGuide: builder.query({
      query: () => `studyguides/me`,
      providesTags: ['STUDY_GUIDES'],
    }),
    postStudyGuide: builder.mutation({
      query: (payload) => ({
        url: 'studyguides',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['STUDY_GUIDES'],
    }),
    editStudyGuide: builder.mutation({
      query: (payload) => ({
        url: `studyguides/${payload.guideId}`,
        method: 'PUT',
        body: {
          title: payload.title,
          subject: payload.subject,
          topics: payload.topics,
        },
      }),
      invalidatesTags: ['STUDY_GUIDES'],
    }),
    deleteStudyGuide: builder.mutation({
      query: (payload) => ({
        url: `studyguides/${payload}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['STUDY_GUIDES'],
    }),
    getStudyGuideById: builder.query({
      query: ({ id }) => `studyguides/${id}`,
      providesTags: ['GET_ONE'],
    }),
  }),
});

export const {
  useGetStudyGuideQuery,
  usePostStudyGuideMutation,
  useEditStudyGuideMutation,
  useDeleteStudyGuideMutation,
  useGetStudyGuideByIdQuery,
} = studyGuidesSlice;
