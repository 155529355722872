import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    latitude: '',
    longitude: '',
    location: '',
  },
};

export const locationSlice = createSlice({
  name: 'locationSlice',
  initialState,
  reducers: {
    setLocation: (state, item) => {
      state.value = item.payload;
    },
  },
});

export const { setLocation } = locationSlice.actions;

export default locationSlice.reducer;
