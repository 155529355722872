import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const noteSlice = createApi({
  reducerPath: 'noteSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['POST'],
  endpoints: (builder) => ({
    tagTypes: ['Get', 'Note'],
    getNotes: builder.query({
      query: () => `note`,
      providesTags: ['Note'],
    }),
    getSingleNotes: builder.query({
      query: ({ noteId }) => `note/:${noteId}`,
    }),
    createNote: builder.mutation({
      query: (payload) => ({
        url: 'note',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Note' }],
    }),
    editNote: builder.mutation({
      query: (payload) => ({
        url: `note/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Note' }],
    }),
    deleteNote: builder.mutation({
      query: (payload) => ({
        url: `note/:${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Note' }],
    }),
  }),
});

export default noteSlice;

export const {
  useGetNotesQuery,
  useGetSingleNotesQuery,
  useCreateNoteMutation,
  useEditNoteMutation,
  useDeleteNoteMutation,
} = noteSlice;
