import jwt_decode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    token: '',
    user: '',
    decoded: {},
    expired: false,
  },
};

const checkTokenExpirationMiddleware = (token) => {
  if (token == null) {
    return true;
  }
  return jwt_decode(token).exp < Date.now() / 1000;
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    getToken: (state) => {
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      state.value = {
        token: token,
        user: JSON.parse(user),
        decoded: token == null ? false : jwt_decode(token),
        expired: checkTokenExpirationMiddleware(token),
      };

      if (checkTokenExpirationMiddleware(token)) {
        localStorage.clear();
      }
    },
    removeToken: (state) => {
      localStorage.clear();
      state.value = {
        token: null,
        decoded: false,
        user: null,
        expired: true,
      };
    },
    storeToken: (state, item) => {
      const { token, user, rememberMe } = item.payload;
      if (rememberMe) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
      }
      state.value = {
        token: token,
        user: user,
        decoded: jwt_decode(token),
        expired: checkTokenExpirationMiddleware(token),
      };
    },
  },
});

export const { getToken, removeToken, storeToken } = tokenSlice.actions;

export default tokenSlice.reducer;
