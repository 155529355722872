import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const paymentSlice = createApi({
  reducerPath: 'paymentSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['POST'],
  endpoints: (builder) => ({
    tagTypes: ['POST'],
    flutterCard: builder.mutation({
      query: (payload) => ({
        url: 'payments/flutter-card',
        method: 'POST',
        body: payload,
      }),
    }),
    flutterAuthorize: builder.mutation({
      query: (payload) => ({
        url: 'payments/flutter-cardpin',
        method: 'POST',
        body: payload,
      }),
    }),
    flutterRedirect: builder.mutation({
      query: (payload) => ({
        url: `payments/flutter-redirect`,
        method: 'POST',
        body: payload,
      }),
    }),
    flutterValidateOtp: builder.mutation({
      query: (payload) => ({
        url: 'payments/flutter-validate-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyPayment: builder.mutation({
      query: (payload) => ({
        url: 'payments/verify',
        method: 'POST',
        body: payload,
      }),
    }),
    stripeVerifyPayment: builder.mutation({
      query: (payload) => ({
        url: 'payments/stripe-verify',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useFlutterCardMutation,
  useFlutterAuthorizeMutation,
  useFlutterRedirectMutation,
  useFlutterValidateOtpMutation,
  useVerifyPaymentMutation,
  useStripeVerifyPaymentMutation,
} = paymentSlice;
