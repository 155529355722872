import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    avatar: '',
  },
};

export const userDataSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAvatar: (state, item) => {
      state.value.avatar = item.payload;
    },
  },
});

export const { setAvatar } = userDataSlice.actions;

export default userDataSlice.reducer;
