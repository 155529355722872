import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userSlice = createApi({
  reducerPath: 'userSlice ',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get'],
  endpoints: (builder) => ({
    tagTypes: ['Get'],
    getAvatar: builder.query({
      query: () => `user/avatars`,
    }),
    updateBio: builder.mutation({
      query: (payload) => ({
        url: 'user/disable-account',
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const { useGetAvatarQuery, useUpdateBioMutation } = userSlice;
