import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="62"
      fill="none"
      viewBox="0 0 71 62"
    >
      <path
        fill="#000"
        d="M37.439 4.57a3.357 3.357 0 013.333 2.964l.023.393v3.357h5.036a25.176 25.176 0 01.815 50.339l-.815.013H29.047a25.176 25.176 0 01-.816-50.339l.816-.013h5.035V7.927a3.357 3.357 0 013.357-3.357zm11.748 23.498a3.357 3.357 0 00-3.356 3.357v1.678h-1.679a3.357 3.357 0 100 6.714h1.679v1.678a3.357 3.357 0 006.713 0v-1.678h1.679a3.357 3.357 0 000-6.714h-1.679v-1.678a3.357 3.357 0 00-3.357-3.357zm-23.497 0a8.392 8.392 0 100 16.784 8.392 8.392 0 000-16.784zm0 6.714a1.679 1.679 0 110 3.357 1.679 1.679 0 010-3.357z"
      ></path>
      <path
        fill="#F9D51B"
        stroke="#000"
        d="M34.562 1.193h0a3.857 3.857 0 00-3.857 3.857v2.857H26.17v0h-.009l-.815.013v0h-.008a25.676 25.676 0 00.831 51.339h16.785v0h.008l.816-.014v0h.008a25.676 25.676 0 00-.832-51.338h0-4.536V5.02l-.024-.392v-.015l-.002-.014a3.857 3.857 0 00-3.83-3.406zm8.392 29.533h.5v-2.178a2.857 2.857 0 115.713 0v2.178H51.346a2.857 2.857 0 010 5.714H49.167v2.178a2.857 2.857 0 01-5.713 0V36.44H41.275a2.857 2.857 0 110-5.714h1.679zm-25.722-2.723a7.892 7.892 0 1111.161 11.161 7.892 7.892 0 01-11.16-11.162zm7.121 4.04a2.178 2.178 0 10-3.08 3.08 2.178 2.178 0 003.08-3.08z"
      ></path>
    </svg>
  );
}

export default Icon;
