import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
  name: [],
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, item) => {
      state.value = true;
      state.name.push(item.payload);
    },
    closeModal: (state, item) => {
      const { payload } = item;
      const data = state.name.filter((name) => name !== payload);
      state.name = data;
      state.value = false;
    },
    closeModalAll: (state) => {
      state.value = false;
      state.name = [];
    },
  },
});

export const { openModal, closeModal, closeModalAll } = modalSlice.actions;

export default modalSlice.reducer;
