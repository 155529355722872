// import { ErrorBoundary } from 'react-error-boundary';
// import { ErrorBoundary } from '@sentry/react';
import Button from '../Button';

import * as Sentry from '@sentry/react';

import './index.scss';

import './index.scss';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="error">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src="https://purple-roducate-files.s3.eu-west-1.amazonaws.com/roda/rodaSad.png"
          width="200"
        />
        <p className="error__title">Oops!</p>
        <p className="error__title">Sorry, Something went wrong..</p>
        <br />
        <div>
          <Button
            onClick={() => {
              navigate('/');
              window.location.reload();
            }}
          >
            Try Again!
          </Button>
        </div>
      </div>
    </div>
  );
};

const ReactErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ReactErrorBoundary;
