import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  secret: '',
  stripe: null,
  payType: 'card',
};

export const stripeSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    storeIntent: (state, item) => {
      state.secret = item.payload;
    },
    setPayType: (state, item) => {
      const get = localStorage.getItem('payType');
      if (!get) {
        localStorage.setItem('payType', item.payload);
        state.payType = item.payload;
      } else {
        state.payType = get;
      }
    },
  },
});

export const { storeIntent, setPayType } = stripeSlice.actions;

export default stripeSlice.reducer;
