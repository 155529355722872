import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authSlice = createApi({
  reducerPath: 'authSlice',
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_BASE }),
  tagTypes: ['Post'],
  endpoints: (builder) => ({
    tagTypes: ['Post'],
    loginUser: builder.mutation({
      query: (payload) => ({
        url: 'auth/login',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Post'],
    }),
    signupUser: builder.mutation({
      query: (payload) => ({
        url: 'auth/register',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Post'],
    }),
    oAuthUser: builder.mutation({
      query: (payload) => ({
        url: 'auth/oauth',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Post'],
    }),
    tokenValidation: builder.mutation({
      query: (payload) => ({
        url: 'auth/verify',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Post'],
    }),
    addPassword: builder.mutation({
      query: (payload) => ({
        url: 'auth/createPassword',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Post'],
    }),
    getLocation: builder.query({
      query: () => `/location`,
    }),
    submitEmail: builder.mutation({
      query: (payload) => ({
        url: 'auth/forgotpassword',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (payload) => ({
        url: 'auth/verify-password-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    submitNewPassword: builder.mutation({
      query: (payload) => ({
        url: 'auth/forgotpassword',
        method: 'POST',
        body: payload,
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: 'auth/resetpassword',
        method: 'POST',
        body: payload,
      }),
    }),
    resendOtp: builder.mutation({
      query: (payload) => ({
        url: 'auth/resend-otp',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useSignupUserMutation,
  useTokenValidationMutation,
  useQuickSetupMutation,
  useAddPasswordMutation,
  useOAuthUserMutation,
  useGetLocationQuery,
  useSubmitEmailMutation,
  useSubmitNewPasswordMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,
  useResendOtpMutation,
} = authSlice;
