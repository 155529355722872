import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'processing',
  selectPayment: 'select',
  recipe: {
    modalName: '',
    redirect: false,
    link: '',
  },
};

export const paymentStatus = createSlice({
  name: 'paymentStatus',
  initialState,
  reducers: {
    setPaymentScreen: (state, item) => {
      state.value = item.payload;
    },
    selectPayment: (state, item) => {
      state.selectPayment = item.payload;
    },
    closeRecipe: (state, item) => {
      state.recipe = item.payload;
    },
  },
});

export const { setPaymentScreen, selectPayment, closeRecipe } = paymentStatus.actions;

export default paymentStatus.reducer;
