import AlertIcon from '../assets/Alert.png';
import Toaster from 'toastify-js';

export const tostObj = (color) => ({
  duration: 3000,
  newWindow: true,
  gravity: 'top',
  position: 'center',
  style: {
    background: color,
    borderRadius: '30px',
    fontSize: '16px',
    fontWeight: '500',
  },
});

export const successToast = (message) => {
  return Toaster({
    text: message || 'Successful',
    avatar: AlertIcon,
    ...tostObj('var(--purple-2)'),
  }).showToast();
};

export const errorToast = (message) => {
  return Toaster({
    text: message || 'An error scoured',
    avatar: AlertIcon,
    ...tostObj('red'),
  }).showToast();
};
