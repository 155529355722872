import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  game: {},
};

export const gameSelectedSlice = createSlice({
  name: 'gameSelectedSlice',
  initialState,
  reducers: {
    selectedGame: (state, { payload: value }) => {
      state.game = value;
    },
  },
});

export const { selectedGame } = gameSelectedSlice.actions;

export default gameSelectedSlice.reducer;
