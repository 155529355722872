import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  amount: null,
};

export const amountSlice = createSlice({
  name: 'amountSlice',
  initialState,
  reducers: {
    addAmount: (state, item) => {
      state.amount = item.payload;
    },
  },
});

export const { addAmount } = amountSlice.actions;

export default amountSlice.reducer;
