import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="71"
      fill="none"
      viewBox="0 0 70 71"
    >
      <path
        fill="#E7AA6C"
        d="M16.838 36.582l1.835 27.707a3.784 3.784 0 003.784 3.519h25.115a3.784 3.784 0 003.783-3.52l1.807-27.706M54.061 28.002H15.939a1.873 1.873 0 00-1.873 1.873v4.824c0 1.035.839 1.873 1.873 1.873h38.122a1.873 1.873 0 001.873-1.873v-4.824a1.873 1.873 0 00-1.873-1.873z"
      ></path>
      <path
        fill="#E7AA6C"
        d="M53.162 36.582l-1.835 27.697a3.783 3.783 0 01-3.784 3.52H22.428a3.783 3.783 0 01-3.585-2.621c.183.02.366.02.549 0h25.143a3.783 3.783 0 003.784-3.528l1.636-25.068h3.207z"
      ></path>
      <path
        fill="#E7AA6C"
        d="M55.934 29.876v4.815a1.892 1.892 0 01-1.892 1.892h-3.254a1.892 1.892 0 001.892-1.892v-4.825a1.892 1.892 0 00-1.892-1.891h3.273a1.892 1.892 0 011.873 1.901z"
      ></path>
      <path
        fill="#323C6B"
        d="M17.348 44.878a.492.492 0 01-.34-.151.463.463 0 010-.662l8.116-7.833a.474.474 0 11.662.681l-8.126 7.833a.492.492 0 01-.312.132zM17.973 53.495a.473.473 0 01-.379-.832l17.084-16.46a.473.473 0 01.643.69l-5.306 5.118-11.768 11.352a.464.464 0 01-.274.132zM18.483 62.123a.473.473 0 01-.321-.814l26.023-25.077a.475.475 0 11.662.681L34.773 46.61 18.815 61.99a.444.444 0 01-.332.133zM21.69 68.204a.473.473 0 01-.321-.813l31.415-30.27a.475.475 0 11.662.68L22.022 68.073a.492.492 0 01-.331.132zM31.14 68.27a.473.473 0 01-.321-.813L52.13 46.92a.472.472 0 11.652.681L31.471 68.138a.492.492 0 01-.33.132zM40.675 68.272a.473.473 0 01-.33-.814l11.133-10.746a.473.473 0 01.775.51.463.463 0 01-.103.152L40.988 68.14a.51.51 0 01-.313.133z"
      ></path>
      <path
        fill="#323C6B"
        d="M52.575 45.862a.464.464 0 01-.33-.133l-9.148-8.816a.476.476 0 01.662-.68l9.148 8.863a.464.464 0 010 .662.482.482 0 01-.332.104zM52.027 54.498a.492.492 0 01-.331-.132L33.572 36.913a.475.475 0 01.662-.68L52.33 53.684a.472.472 0 01-.322.813h.019zM51.44 63.116a.426.426 0 01-.321-.133l-27.064-26.07a.475.475 0 01.663-.68l10.055 9.695L51.8 62.302a.473.473 0 01-.331.814h-.028zM47.297 68.272a.492.492 0 01-.33-.132L16.695 38.957a.483.483 0 01-.18-.369.473.473 0 01.804-.34l30.27 29.21a.473.473 0 01-.321.814h.028zM37.762 68.272a.491.491 0 01-.331-.133L17.29 48.73a.474.474 0 11.653-.682l20.14 19.411a.473.473 0 01-.322.814zM28.246 68.27a.511.511 0 01-.331-.132l-9.942-9.62a.455.455 0 010-.662.465.465 0 01.662 0l9.942 9.601a.474.474 0 01-.331.814z"
      ></path>
      <path
        fill="#F9D51B"
        d="M37.223 3.475L30.95 15.1l10.718.814-4.446-12.44z"
      ></path>
      <path
        fill="#F9D51B"
        d="M40.401 19.082l-8.674-.662-.776-3.32 10.718.813-1.268 3.169z"
      ></path>
      <path
        fill="#9297AB"
        d="M40.278 28.002l.123-8.92-8.674-.662-1.334 9.582h9.885z"
      ></path>
      <path
        fill="#F7BE56"
        d="M39.493 15.753l.01-.019-3.547-9.913 1.267-2.346 4.446 12.439-2.176-.161zM39.493 15.754l-1.258 3.15h-.038v.01l2.204.17 1.268-3.17-2.176-.16z"
      ></path>
      <path
        fill="#84879C"
        d="M40.401 19.083l-.123 8.92h-9.885l.028-.18h7.691l.123-8.901 2.166.16z"
      ></path>
      <path
        fill="#323C6B"
        d="M36.693 10.985a.464.464 0 01-.435-.502l.501-7.01a.474.474 0 11.946.076l-.53 7.038a.473.473 0 01-.482.398z"
      ></path>
      <path
        fill="#323C6B"
        d="M36.57 13.52a1.504 1.504 0 100-3.008 1.504 1.504 0 000 3.008z"
      ></path>
      <path
        fill="#FE7058"
        d="M19.695 28.003l-2.176-18.37a1.788 1.788 0 011.56-1.977l5.515-.643a1.769 1.769 0 011.968 1.55l2.29 19.44h-9.157z"
      ></path>
      <path
        fill="#EA6453"
        d="M28.86 28.003H26.79l-2.024-17.188a1.778 1.778 0 00-1.968-1.56l-5.25.624v-.246a1.788 1.788 0 011.56-1.977l5.487-.643a1.769 1.769 0 011.968 1.55l2.299 19.44z"
      ></path>
      <path
        fill="#323C6B"
        d="M16.582 24.626a.473.473 0 01-.463-.425l-.946-8.117a1.76 1.76 0 011.532-1.948l10.406-1.22a.475.475 0 11.113.945l-10.405 1.23a.795.795 0 00-.7.89l.946 8.125a.473.473 0 01-.417.52h-.066z"
      ></path>
      <path
        fill="#00AB5F"
        d="M50.485 28.003l2.923-11.474-7.605-1.94-3.415 13.414h8.097z"
      ></path>
      <path
        fill="#00C56D"
        d="M51.705 16.094l-.264-.067-3.274 11.976h2.318l2.923-11.474-1.703-.435z"
      ></path>
      <path
        fill="#323C6B"
        d="M46.436 28.477h-.113a.464.464 0 01-.34-.568l3.206-12.467a.488.488 0 01.946.236L46.9 28.118a.473.473 0 01-.464.359z"
      ></path>
      <path
        fill="#9297AB"
        d="M45.803 14.59l5.6-6.111 2.015 8.05-7.615-1.94z"
      ></path>
      <path
        fill="#323C6B"
        d="M53.417 17.002h-.123l-7.567-1.94a.473.473 0 01-.237-.775l5.591-6.083a.473.473 0 01.804.209l2.034 8.002a.473.473 0 01-.123.445.464.464 0 01-.379.142zm-6.735-2.677l6.083 1.551-1.608-6.423-4.475 4.872zM28.86 28.476h-9.165a.473.473 0 01-.464-.416L17.027 9.69a2.24 2.24 0 011.977-2.498l5.59-.652a2.261 2.261 0 012.498 1.967l2.232 19.44a.483.483 0 01-.113.368.474.474 0 01-.35.161zm-8.74-.946h8.258L26.146 8.611a1.269 1.269 0 00-.483-.87 1.296 1.296 0 00-.945-.274l-5.525.652a1.315 1.315 0 00-1.22 1.457L20.12 27.53zM41.669 16.386l-10.718-.813a.462.462 0 01-.378-.246.482.482 0 010-.454l6.234-11.617a.454.454 0 01.454-.246.464.464 0 01.406.313l4.446 12.448a.464.464 0 01-.444.634v-.019zm-9.961-1.702l9.27.7-3.84-10.746-5.43 10.046z"
      ></path>
      <path
        fill="#323C6B"
        d="M40.401 19.556l-8.712-.662a.472.472 0 01-.426-.369l-.775-3.32a.454.454 0 01.094-.407.482.482 0 01.398-.17l10.727.813a.502.502 0 01.368.227.482.482 0 010 .426l-1.267 3.16a.473.473 0 01-.407.302zm-8.296-1.58l7.984.606.899-2.242-9.46-.719.577 2.356z"
      ></path>
      <path
        fill="#323C6B"
        d="M40.278 28.477h-9.885a.474.474 0 01-.36-.161.5.5 0 01-.113-.378l1.296-9.583a.482.482 0 01.511-.407l8.674.663a.473.473 0 01.435.482l-.122 8.92a.482.482 0 01-.436.464zm-9.346-.946h8.873l.114-7.974-7.757-.625-1.23 8.599zM50.485 28.477h-8.097a.463.463 0 01-.458-.374.482.482 0 01.004-.213l3.405-13.423a.492.492 0 01.392-.348.473.473 0 01.185.008l7.568 1.891a.483.483 0 01.35.578l-2.886 11.521a.483.483 0 01-.463.36zm-7.483-.946h7.133L52.85 16.87l-6.688-1.703-3.16 12.364zM47.572 68.272H22.428a4.238 4.238 0 01-4.228-3.964l-1.835-27.726a.463.463 0 01.435-.5.482.482 0 01.51.434l1.836 27.716a3.292 3.292 0 003.282 3.075h25.144a3.301 3.301 0 003.282-3.075l1.835-27.65a.483.483 0 01.689-.388.463.463 0 01.257.455L51.8 64.309a4.257 4.257 0 01-4.228 3.963z"
      ></path>
      <path
        fill="#323C6B"
        d="M54.06 37.045H15.94a2.346 2.346 0 01-2.347-2.355v-4.824a2.346 2.346 0 012.346-2.346h38.122a2.346 2.346 0 012.346 2.346v4.824a2.346 2.346 0 01-2.346 2.355zm-38.12-8.57a1.4 1.4 0 00-1.4 1.4v4.815a1.4 1.4 0 001.4 1.4h38.12a1.4 1.4 0 001.4-1.4v-4.824a1.4 1.4 0 00-1.4-1.4l-38.12.009zM51.27 69.966a.464.464 0 01-.265-.861 6.11 6.11 0 002.687-4.673l.113-1.75a.482.482 0 01.682-.391.462.462 0 01.264.457l-.123 1.75a7.029 7.029 0 01-3.093 5.392.473.473 0 01-.265.076zM54.42 60.997a.474.474 0 01-.445-.5v-.654a.502.502 0 01.502-.435.482.482 0 01.445.502v.652a.473.473 0 01-.502.435zM11.228 35.173a.473.473 0 01-.473-.473v-.417a.473.473 0 11.946 0v.407a.483.483 0 01-.473.483zM11.228 32.524a.473.473 0 01-.473-.473v-2.175a5.183 5.183 0 012.602-4.475.483.483 0 01.652.17.473.473 0 01-.18.644 4.257 4.257 0 00-2.128 3.66v2.176a.482.482 0 01-.473.473z"
      ></path>
      <path
        fill="#fff"
        d="M24.377 34.142h-.445a.7.7 0 010-1.4h.445a.7.7 0 110 1.4zM36.249 34.142h-9.081a.7.7 0 110-1.4h9.08a.7.7 0 110 1.4z"
      ></path>
    </svg>
  );
}

export default Icon;
