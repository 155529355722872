import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="53"
      fill="none"
      viewBox="0 0 59 53"
    >
      <path
        fill="#F37424"
        stroke="#000"
        d="M30.872 30.135l20.813-9.842.714-.338V29.767a2.328 2.328 0 004.656 0V17.694l.163-.15c1.301-1.19 1.072-3.459-.665-4.277h0L31.584 1.458v.003l-.713-.337a3.742 3.742 0 00-3.199 0s0 0 0 0L1.993 13.269a2.61 2.61 0 000 4.719l28.879 12.147zm0 0a3.743 3.743 0 01-3.199 0s0 0 0 0L1.993 17.988l28.879 12.147zm17.698-1.61v4.07a19.298 19.298 0 11-38.596 0v-4.07l17.272 8.168h0a4.742 4.742 0 004.053 0h0l17.271-8.167z"
      ></path>
    </svg>
  );
}

export default Icon;
