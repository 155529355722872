import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="69"
      fill="none"
      viewBox="0 0 70 69"
    >
      <path
        fill="#9297AB"
        d="M54.96 28.34v18.285a49.841 49.841 0 00-7.975 2.147c-4.418 1.608-9.8 4.22-13.414 8.362-7.217-8.267-21.387-10.509-21.387-10.509V28.34"
      ></path>
      <path
        fill="#84879C"
        d="M54.96 28.34v18.285a49.843 49.843 0 00-7.975 2.147c-4.417 1.608-9.8 4.22-13.413 8.362a21.762 21.762 0 00-2.356-2.308c3.585-3.85 8.675-6.31 12.94-7.851a48.645 48.645 0 017.975-2.147V28.34h2.828z"
      ></path>
      <path
        fill="#9297AB"
        d="M33.572 9.629L1.722 24.092l31.85 14.455 31.86-14.455L33.571 9.63z"
      ></path>
      <path
        fill="#84879C"
        d="M65.431 24.092l-4.03 1.826-28.775-13.063-27.82 12.637-3.084-1.4L33.572 9.63l31.86 14.463z"
      ></path>
      <path
        fill="#323C6B"
        d="M62.858 33.107a.472.472 0 01-.435-.275l-2.705-5.96-13.367-6.006a.464.464 0 01.02-.868.482.482 0 01.359.007l13.536 6.064a.482.482 0 01.237.236l2.78 6.12a.465.465 0 01-.425.663v.019z"
      ></path>
      <path
        fill="#F9D51B"
        d="M63.142 41.905v4.767l4.02-1.267-1.816-7.956h-4.398l-1.817 7.956 4.011 1.267"
      ></path>
      <path
        fill="#323C6B"
        d="M63.142 47.146a.284.284 0 01-.123 0l-4.01-1.277a.483.483 0 01-.322-.558l1.816-7.946a.473.473 0 01.463-.369h4.4a.473.473 0 01.463.369l1.816 7.946a.473.473 0 01-.322.558l-4.01 1.277h-.17zm0-5.676a.473.473 0 01.473.473v4.087l2.99-.946-1.637-7.16h-3.642l-1.637 7.16 2.98.946v-4.124a.473.473 0 01.473-.473v.037zM45.112 53.058a.483.483 0 01-.435-.275.462.462 0 01.246-.624 44.067 44.067 0 012.838-1.154 52.524 52.524 0 015.08-1.523.487.487 0 11.227.946c-1.688.404-3.352.9-4.985 1.485-.88.312-1.77.672-2.838 1.126a.482.482 0 01-.133.019zM42.151 54.514a.491.491 0 01-.416-.236.472.472 0 01.19-.643l.17-.095.435-.236a.464.464 0 01.633.198.474.474 0 01-.198.634l-.416.227-.17.095a.472.472 0 01-.228.056zM10.103 17.556a.482.482 0 11-.19-.946l6.972-3.16a.463.463 0 01.625.237.482.482 0 01-.237.625l-6.971 3.16a.512.512 0 01-.2.084zM19.354 13.328a.492.492 0 01-.435-.275.473.473 0 01.236-.624l.426-.199a.472.472 0 11.388.861l-.426.199a.425.425 0 01-.189.038z"
      ></path>
      <path
        fill="#F9D51B"
        d="M63.142 38.452a2.913 2.913 0 100-5.827 2.913 2.913 0 000 5.827z"
      ></path>
      <path
        fill="#323C6B"
        d="M63.142 38.925a3.396 3.396 0 110-6.792 3.396 3.396 0 010 6.792zm0-5.837a2.45 2.45 0 100 4.9 2.45 2.45 0 000-4.9zM33.572 39.019a.425.425 0 01-.19 0L1.523 24.517a.473.473 0 010-.86l31.86-14.454a.464.464 0 01.387 0l31.85 14.454a.473.473 0 010 .86L33.77 38.981a.49.49 0 01-.198.038zM2.838 24.092l30.734 13.934 30.752-13.934-30.752-13.943L2.838 24.092z"
      ></path>
      <path
        fill="#323C6B"
        d="M33.572 57.606a.454.454 0 01-.35-.16c-7.02-8.041-20.925-10.33-21.104-10.35a.482.482 0 01-.407-.472V28.84a.473.473 0 01.946 0v17.387c2.365.425 14.19 2.838 20.915 10.197 3.027-3.292 7.482-6.016 13.243-8.097a52.213 52.213 0 017.662-2.1V28.84a.473.473 0 01.946 0v17.784a.482.482 0 01-.397.473 47.623 47.623 0 00-7.899 2.128c-5.836 2.1-10.282 4.862-13.243 8.22a.464.464 0 01-.312.161z"
      ></path>
      <path
        fill="#fff"
        d="M18.55 29.247l-.378-.17a.625.625 0 01.52-1.135l.388.17a.634.634 0 01.303.832.624.624 0 01-.833.303zM28.284 33.769l-7.369-3.425a.625.625 0 01.52-1.135l7.379 3.424a.616.616 0 01.302.833.622.622 0 01-.832.303z"
      ></path>
    </svg>
  );
}

export default Icon;
