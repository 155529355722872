import { useEffect } from 'react';
import { getToken } from '../store/slice/tokenSlice';
import { useDispatch } from 'react-redux';
import StandardErrorBoundary from '../components/ErrorBoundary';

const SetUserToken = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToken());
  }, []);

  return <StandardErrorBoundary>{children}</StandardErrorBoundary>;
};

export default SetUserToken;
