import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userSubscriptionSlice = createApi({
  reducerPath: 'userSubscriptionSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get'],
  endpoints: (builder) => ({
    tagTypes: ['Get'],
    getUserSub: builder.query({
      query: () => `userSubscription`,
    }),
  }),
});

export const { useGetUserSubQuery } = userSubscriptionSlice;
