import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const searchSlice = createApi({
  reducerPath: 'searchSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['POST'],
  endpoints: (builder) => ({
    tagTypes: ['GET'],
    search: builder.query({
      query: (payload) => `resource/search?q=${payload}`,
    }),
  }),
});

export const { useSearchQuery, useLazySearchQuery } = searchSlice;
