import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const communitySlice = createApi({
  reducerPath: 'communitySlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get', 'GET_EVENT', 'GET_COMMUNITIES'],
  endpoints: (builder) => ({
    getCommunity: builder.query({
      query: () => `community/general`,
      providesTags: ['GET_COMMUNITIES'],
    }),
    getEvents: builder.query({
      query: () => `events/general`,
      providesTags: ['GET_EVENT'],
    }),
    likeEvent: builder.mutation({
      query: (id) => ({
        url: `events/${id}`,
        method: 'PATCH',
      }),
    }),
    toggleAttendEvent: builder.mutation({
      query: (payload) => ({
        url: `events/attend/${payload}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [{ type: 'GET_EVENT' }],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetCommunityQuery,
  useLikeEventMutation,
  useToggleAttendEventMutation,
} = communitySlice;
