import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {},
  chat: {},
  shareObj: {
    type: '',
    id: '',
  },
};

export const friendRequestSlice = createSlice({
  name: 'friendRequestSlice',
  initialState,
  reducers: {
    friendObj: (state, item) => {
      state.value = item.payload;
    },
    startChat: (state, item) => {
      state.chat = item.payload;
    },
    shareObjFuc: (state, item) => {
      state.shareObj = item.payload;
    },
  },
});

export const { friendObj, startChat, shareObjFuc } = friendRequestSlice.actions;

export default friendRequestSlice.reducer;
