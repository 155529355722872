import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const pushNotifySlice = createApi({
  reducerPath: 'pushNotifySlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    tagTypes: ['Get'],
    getPush: builder.query({
      query: () => `push-notification`,
      providesTags: ['Get'],
    }),
    updatePush: builder.mutation({
      query: (payload) => ({
        url: `push-notification`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Get' }],
    }),
  }),
});

export const { useGetPushQuery, useUpdatePushMutation } = pushNotifySlice;
