import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userPlanSlice = createApi({
  reducerPath: 'userPlanSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['POST'],
  endpoints: (builder) => ({
    tagTypes: ['POST'],
    getUserPlan: builder.query({
      query: (payload) => ({
        url: `subscription/`,
        method: 'GET',
        body: payload,
      }),
    }),
    freePlan: builder.mutation({
      query: (payload) => ({
        url: 'subscription/free',
        method: 'POST',
        body: payload,
      }),
    }),
    fullPlan: builder.mutation({
      query: (payload) => ({
        url: 'subscription/full',
        method: 'POST',
        body: payload,
      }),
    }),
    customizePlan: builder.mutation({
      query: (payload) => ({
        url: 'subscription/customize',
        method: 'POST',
        body: payload,
      }),
    }),
    addPlan: builder.mutation({
      query: (payload) => ({
        url: 'subscription/add-plan',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useFreePlanMutation,
  useFullPlanMutation,
  useCustomizePlanMutation,
  useAddPlanMutation,
} = userPlanSlice;
