import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'home',
  position: localStorage.getItem('sidebar')
    ? JSON.parse(localStorage.getItem('sidebar'))
    : true,
  mobile: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    sidebarActive: (state, item) => {
      state.value = item.payload;
    },
    sidebarPosition: (state, item) => {
      if (item.payload) {
        state.position = true;
        localStorage.setItem('sidebar', 'true');
      } else {
        state.position = false;
        localStorage.setItem('sidebar', 'false');
      }
    },
    mobileSidebar: (state) => {
      state.mobile = true;
    },
    mobileSidebarClose: (state) => {
      state.mobile = false;
    },
  },
});

export const {
  sidebarActive,
  sidebarPosition,
  mobileSidebar,
  mobileSidebarClose,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
