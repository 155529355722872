import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lifePodcast: {},
  playing: false,
  loading: 0,
  currentTime: 0,
};

export const lifePodcastSlice = createSlice({
  name: 'lifePodcastSlice',
  initialState,
  reducers: {
    selectedSkillPodcast: (state, { payload: value }) => {
      state.lifePodcast = value;
    },
    playState: (state) => {
      state.playing = !state.playing;
    },
    loadingState: (state, { payload: value }) => {
      state.loading = value;
    },
    setCurrentTime: (state, { payload: value }) => {
      state.currentTime = value;
    },
  },
});

export const { selectedSkillPodcast, playState, loadingState, setCurrentTime } =
  lifePodcastSlice.actions;

export default lifePodcastSlice.reducer;
